<template>
  <!--begin::Form-->
  <form class="form" novalidate="novalidate">
    <!--begin::Input group-->
    <div class="row">
      <!--begin::Col-->
      <div class="col-xl-6 fv-row mb-10">
        <!--begin::Label-->
        <label class="form-label fw-semi-bold">First name</label>
        <!--end::Label-->
        <!--begin::Input-->
        <input
          v-model="form.first_name"
          :class="['form-control form-control-solid', validateModel($v.form.first_name.$error)]"
          type="text"
        />
        <!--end::Input-->
      </div>
      <!--begin::Col-->
      <div class="col-xl-6 fv-row mb-10">
        <!--begin::Label-->
        <label class="form-label fw-semi-bold">Last name</label>
        <!--end::Label-->

        <!--begin::Input-->
        <input
          v-model="form.last_name"
          :class="['form-control form-control-solid', validateModel($v.form.last_name.$error)]"
          type="text"
        />
        <!--end::Input-->
      </div>
    </div>
    <!--end::Input group-->

    <!--begin::Input group-->
    <div class="row">
      <!--begin::Col-->
      <div class="col-xl-6 fv-row mb-10">
        <!--begin::Label-->
        <label class="form-label fw-semi-bold">Phone</label>
        <!--end::Label-->

        <!--begin::Input-->
        <input
          v-model="form.phone"
          v-mask="['(###) ###-####']"
          :class="['form-control form-control-solid', validateModel($v.form.phone.$error)]"
          type="text"
        />
        <!--end::Input-->
      </div>
      <!--end::Col-->
      <!--begin::Col-->
      <div class="col-xl-6 fv-row mb-10">
        <!--begin::Label-->
        <label class="form-label fw-semi-bold">Email</label>
        <!--end::Label-->

        <!--begin::Input-->
        <input
          v-model="form.email"
          :class="['form-control form-control-solid', validateModel($v.form.email.$error)]"
          type="email"
        />
        <!--end::Input-->
      </div>
    </div>
    <!--end::Input group-->

    <div class="fv-row mb-10">
      <!--begin::Label-->
      <label class="form-label fw-semi-bold">Address</label>
      <!--end::Label-->
      <!--begin::Input-->
      <vue-google-autocomplete
        id="address_autocomplete"
        ref="address_autocomplete"
        :class="['form-control form-control-solid', validateModel($v.form.address.$error)]"
        country="ca"
        :value="form.address"
        @placechanged="setAddressFields"
        @error="handleError"
      />
      <input
        v-model="form.lat"
        :class="['form-control form-control-solid', validateModel($v.form.lat.$error)]"
        type="hidden"
      />
      <input
        v-model="form.lon"
        :class="['form-control form-control-solid', validateModel($v.form.lon.$error)]"
        type="hidden"
      />
      <!--end::Input-->
    </div>

    <!--begin::Input group-->
    <div class="row">
      <div class="col-md-6 fv-row mb-10">
        <!--begin::Label-->
        <label class="form-label fw-semi-bold">Street</label>
        <!--end::Label-->
        <!--begin::Input-->
        <input v-model="form.address_line_1" class="form-control form-control-solid" type="text" />
        <!--end::Input-->
      </div>
      <div class="col-md-6 fv-row mb-10">
        <!--begin::Label-->
        <label class="form-label fw-semi-bold">City</label>
        <!--end::Label-->
        <!--begin::Input-->
        <input v-model="form.city" class="form-control form-control-solid" type="text" />
        <!--end::Input-->
      </div>
    </div>
    <!--end::Input group-->

    <!--begin::Input group-->
    <div class="row">
      <div class="col-md-6 fv-row mb-10">
        <!--begin::Label-->
        <label class="form-label fw-semi-bold">Postal</label>
        <!--end::Label-->
        <!--begin::Input-->
        <input v-model="form.postal" class="form-control form-control-solid" type="text" />
        <!--end::Input-->
      </div>
      <div class="col-md-6 fv-row mb-10">
        <!--begin::Label-->
        <label class="form-label fw-semi-bold">Province</label>
        <!--end::Label-->
        <!--begin::Input-->
        <input v-model="form.province" class="form-control form-control-solid" type="text" />
        <!--end::Input-->
      </div>
    </div>
    <!--end::Input group-->

    <div v-if="managers.data && !hasExtendedRoles" class="fv-row mb-10">
      <label class="form-label fw-semi-bold">Manager</label>
      <px-select
        v-model="form.manager_id"
        classes="form-select form-select-solid"
        placeholder="Select manager"
        :items="managers.data"
        :state="$v.form.manager_id.$error"
        track-by="id"
        :text-by="['attributes.first_name', ' ', 'attributes.last_name']"
        select2-id="select2_managers"
      />
    </div>

    <!--begin::Actions-->
    <div class="d-flex flex-stack">
      <px-btn color="light" extended-classes="btn-active-light-primary me-2" @click.native="close">Cancel </px-btn>
      <px-btn v-if="item" :in-process="updateInProcess" :disabled="updateInProcess" @click.native="onUpdate">
        Update
      </px-btn>
      <px-btn v-else :in-process="createInProcess" :disabled="createInProcess" @click.native="onSubmit">Create </px-btn>
    </div>
    <!--end::Actions-->
  </form>
  <!--end::Form-->
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

import VueGoogleAutocomplete from 'vue-google-autocomplete';

import ProjectMixin from '@/mixins/ProjectMixin';

export default {
  mixins: [ProjectMixin],
  components: { VueGoogleAutocomplete },
  props: {
    item: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      message: 'projects/message',
      names: 'projects/names',
      managers: 'users/managers',
    }),
  },
  data() {
    return {
      address: '',

      updateInProcess: false,
      createInProcess: false,
    };
  },
  methods: {
    ...mapActions({
      create: 'projects/create',
      update: 'projects/update',
      actionManagersIndex: 'users/managersIndex',
    }),
    ...mapMutations({
      setNames: 'projects/SET_NAMES',
      setItem: 'projects/SET_ITEM',
    }),
    setAddressFields(addressData, placeResultData) {
      let resultStreet = [];
      if (addressData?.street_number) resultStreet.push(addressData.street_number);
      if (addressData?.subpremise) resultStreet.push(addressData.subpremise);
      if (addressData?.route) resultStreet.push(addressData.route);
      this.form.address = placeResultData?.formatted_address || '';
      this.form.address_line_1 = resultStreet.join(' ');
      this.form.lat = addressData?.latitude || '';
      this.form.lon = addressData?.longitude || '';
      this.form.city = addressData?.locality || '';
      this.form.province = addressData?.administrative_area_level_1 || '';
      this.form.postal = addressData?.postal_code || '';
    },
    handleError(error) {
      alert(error);
    },
    async initForm(data = null) {
      await this.v_toast(this.message?.status, 'Created successfully!');
      await this.$v.form.$reset();
      await this.defaultValues();
      this.$emit('onClose', data);
    },
    close() {
      this.defaultValues();
      this.$emit('onClose', null);
    },
    async onSubmit() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return false;
      }

      this.createInProcess = true;

      if (this.hasExtendedRoles) this.form.manager_id = this.user?.id;

      let data = {
        subject: this.form,
      };

      const response = await this.create(data);

      if (response.status === 400) {
        this.createInProcess = false;

        if (response.data[0] === 'Address has already been taken') {
          this.v_toast(response.status, response.data[0], () => {}, {
            timer: 5000,
          });
        } else {
          this.v_toast(
            301,
            `Only users with the "Manager" role can create projects! \n Please contact the administrator.`,
            () => {},
            { timer: 5000 }
          );
        }
      } else {
        const newName = {
          ...response.data.data,
          attributes: {
            address: response.data.data.attributes.address,
            first_name: response.data.data.attributes.first_name,
            last_name: response.data.data.attributes.last_name,
            manager_username: `${response.data.data.attributes.manager?.data?.attributes?.first_name || undefined} ${
              response.data.data.attributes.manager?.data?.attributes?.last_name || undefined
            }`,
          },
        };

        this.setNames([newName, ...this.names]);

        this.createInProcess = false;

        await this.initForm(newName);
      }
    },
    async onUpdate() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return false;
      }

      this.updateInProcess = true;

      let data = this.form;
      data.manager_id = this.item.attributes.manager.data.id;

      await this.update({
        id: this.item?.id || null,
        params: { ...data },
      });
      this.setItem({
        ...this.item,
        attributes: {
          ...this.item.attributes,
          ...data,
        },
      });

      this.updateInProcess = false;

      this.$emit('on-update');
      await this.initForm();
    },
  },
  async mounted() {
    this.form.has_designer_role_only = !this.hasExtendedRoles;

    if (!this.hasExtendedRoles) {
      await this.actionManagersIndex({
        per: 10000,
        fields: 'full_name,first_name,last_name',
      });
    }

    if (this.item) {
      this.form.first_name = this.item?.attributes?.first_name || '';
      this.form.last_name = this.item?.attributes?.last_name || '';
      this.form.phone = this.item?.attributes?.phone || '';
      this.form.email = this.item?.attributes?.email || '';
      this.form.address = this.item?.attributes?.address || '';
      this.form.address_line_1 = this.item?.attributes?.address_line_1 || '';
      this.form.lat = this.item?.attributes?.lat || '';
      this.form.lon = this.item?.attributes?.lon || '';
      this.form.city = this.item?.attributes?.city || '';
      this.form.province = this.item?.attributes?.province || '';
      this.form.postal = this.item?.attributes?.postal || '';
    }
  },
};
</script>

<style lang="scss">
.pac-container {
  z-index: 2000;

  &::after {
    display: none !important;
  }
}
</style>
