import { required, requiredIf, email } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      form: {
        has_designer_role_only: false,
        manager_id: '',
        first_name: '',
        last_name: '',
        address: '',
        phone: '',
        email: '',
        lat: '',
        lon: '',
        address_line_1: '',
        city: '',
        postal: '',
        province: '',
      },
      showModal: false,
    };
  },
  validations: {
    form: {
      manager_id: { required: requiredIf((prop) => prop.has_designer_role_only === true) },
      first_name: { required },
      last_name: { required },
      address: { required },
      phone: { required },
      email: { required, email },
      lat: { required },
      lon: { required },
    },
  },
  computed: {},
  methods: {
    defaultValues() {
      this.form = {
        has_designer_role_only: false,
        manager_id: '',
        first_name: '',
        last_name: '',
        address: '',
        phone: '',
        email: '',
        lat: '',
        lon: '',
        address_line_1: '',
        city: '',
        postal: '',
        province: '',
      };
    },
  },
};
